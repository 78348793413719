import styled from 'styled-components'
import { media } from '../utils/style-utils'

const CTALink = styled.a`
  color: #f49303;
  font-size: 2em;
  text-transform: uppercase;
  text-decoration: none;
  padding: 0.5rem 0.1em;
  border-bottom: 2px solid #f49303;
  transition: all 0.2s ease-in;
  &:hover {
    color: #2ea9d6;
    border-color: #2ea9d6;
  }
  ${media.tabletDown`
    font-size: 1.5em;
    padding: 0.2em 0.1em;
  `};
`

export default CTALink
