import React from 'react'
import styled from 'styled-components'

import Layout from '../components/layout'
import CTALink from '../components/cta-link'
import { media } from '../utils/style-utils'

import img1 from '../assets/images/ueber_uns_1.jpg'
import img2 from '../assets/images/ueber_uns_2.jpg'

const AboutGrid = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${props => (props.textOnly ? 'center' : 'space-between')};
  flex-wrap: wrap;
  align-items: center;
`

const Text = styled.div`
  width: 50%;
  text-align: ${props => (props.centered ? 'center' : 'inherit')};
  ${media.tabletDown`
    width: 100%;
    text-align: center;
  `};
`

const Image = styled.div`
  flex: 0 1 auto;
  width: 40%;
  ${media.tabletDown`
    width: 100%;
  `};
`

const Spacer = styled.div`
  height: 2em;
  ${media.tabletDown`
    height: 1em;
  `};
`
const AboutPage = props => (
	<Layout location={props.location}>
		<div>
			<AboutGrid>
				<Text>
					<h1>Leidenschaft und Kompetenz</h1>
					<Spacer/>
					<p>
						Mit neuen Impulsen und langjähriger Erfahrung entwickeln, planen und
						realisieren wir passgenaue Präsentationslösungen mit hohem kreativem
						und handwerklichem Know-How. Wir lieben die Herausforderungen, die
						unsere Kunden mit ihren individuellen und unterschiedlichen
						Anforderungen an uns stellen. In kleinen, interdisziplinären Teams
						schaffen wir individuelle Markenräume, gebautes „Corporate Design”,
						bei der die persönliche Begegnung im Vordergrund steht.
					</p>
				</Text>
				<Image>
					<img
						src={img1}
						alt={'Strichzeichnung Messestand'}
						style={{ width: '100%', height: 'auto' }}
					/>
				</Image>
			</AboutGrid>
			<Spacer/>
			<Spacer/>
			<AboutGrid textOnly>
				<Text>
					<h1>Die passende Mischung</h1>
					<Spacer/>
					<p>
						Das Team von Neuroth-Messemarketing versteht sich nicht nur als
						Partner seiner Kunden, sondern auch als deren Botschafter. Wir hören
						zu, verstehen und steigen tief in die Materie ein, um die richtige
						Mischung aus ästhetischen, funktionalen und wirtschaftlichen
						Überlegungen im Sinne unserer Kunden zu berücksichtigen. Unsere
						Entwürfe und Konzepte haben Bestand und entwickeln sich mit unseren
						Kunden und Ihren Anforderungen stetig weiter.
					</p>
				</Text>
			</AboutGrid>
			<Spacer/>
			<Spacer/>
			<AboutGrid>
				<Image>
					<img
						src={img2}
						alt={'Strichzeichnung Messestand'}
						style={{ width: '100%', height: 'auto' }}
					/>
				</Image>
				<Text>
					<h1>Umfassend und Leistungsstark</h1>
					<Spacer/>
					<p>
						Neuroth Messemarketing begleitet Ihr Präsentationskonzept von der
						perfekte CAD-Planung und 3-D Visualisierungen bis zur vollständigen
						Umsetzung, die auch den Einsatz modernen Medientechnik, sowie
						Digitaldrucklösungen umfasst, die viel kreativen Spielraum und
						überraschend viel Flexibilität bieten. Als Komplett-Dienstleister
						kümmern wir uns um technische Bestellungen, Planungsrichtlinien,
						Bauvorschriften und logistische Fragen genauso wie Mobiliar,
						Präsentationsmedien oder Standbewirtung. In der operativen
						Planungsphase übernimmt Neuroth Messemarketing mit über 30 Jahren
						Erfahrung alle Aufgaben, für die Sie keine Zeit haben.
					</p>
				</Text>
			</AboutGrid>
			<Spacer/>
			<Spacer/>
			<Spacer/>
			<AboutGrid textOnly>
				<Text centered>
					<h1>Jederzeit der direkten Draht</h1>
					<Spacer/>
					<p>
						Es ist mehr als nur Service oder ein gutes Gefühl. Der ständige Dialog
						zu unseren Kunden und die persönliche Präsenz stellen den Erfolg Ihrer
						Präsentation sicher. Während der strategischen Planung, zwischen den
						Terminen oder vor Ort beim Aufbau des Messestandes sind enge
						Absprachen und schnelle Entscheidungen oft unabdingbar.
					</p>
				</Text>
			</AboutGrid>
			<Spacer/>
			<Spacer/>
			<AboutGrid textOnly>
				<Text centered>
					<CTALink href={'mailto:neuroth@neuroth-messemarketing.de'}>
						Lernen Sie uns jetzt kennen
					</CTALink>
				</Text>
			</AboutGrid>
		</div>
	</Layout>
)

export default AboutPage
